import React from "react"
import useTranslations from "../../utils/useTranslations"
import axios from "axios"
import { CSSTransition } from 'react-transition-group' 
import heroBg from '../../images/company/adobe-stock-278687683.jpg'
import {Link} from "gatsby"
import img1 from "../../images/company/DSC00257.jpg"
import img2 from "../../images/company/DSC05285.jpg"
import img3 from "../../images/company/DSC05422.jpg"
import img4 from "../../images/company/DSC05616.jpg"
import img5 from "../../images/company/DSC_0912.jpg"
import img6 from "../../images/company/DSC_7317.jpg"
import img7 from "../../images/company/grouppic.jpg"
import img8 from "../../images/company/SNY04353.jpg"


class CompanyJobs extends React.Component {

	constructor(props){
		super(props)
		this.state = {
			jobs:[],
			show: false
		}
	}

	componentDidMount(){
        var jobs = []
		let fetchJobsBT = axios.get('https://boards-api.greenhouse.io/v1/boards/bittorrent/departments')
		let fetchJobsTron = axios.get('https://boards-api.greenhouse.io/v1/boards/rainberry/departments')
		Promise.all([fetchJobsBT, fetchJobsTron])
			.then(values => {
				values.forEach((res) => {
					let departments = res.data.departments;
					for ( let i = 0 ; i < departments.length ; i++ ){
						//continue if department has no jobs
						if (departments[i].jobs.length === 0) continue;

						let departmentIndex = jobs.findIndex((entry) => {
							return entry.department === departments[i].name;
						})

						if(departmentIndex === -1) {
							// if department does not exist in jobs array
							// push to jobs array
						    jobs.push({department:departments[i].name, positions:departments[i].jobs});
						} else {
							// if department already exists in jobs array
							// concat new positions into existing department positions array
							jobs[departmentIndex].positions = jobs[departmentIndex].positions.concat(departments[i].jobs);
						}
					}
					this.setState({
						jobs:jobs,
						show:true
					});
				})
			})

	}

	mappedColumns(){
		let departments = this.mappedDepartments()
		let departmentsPerColumn = Math.ceil(departments.length/3)
		let tmpList = []
		for(let i = 0; i < 3; i++){
			tmpList.push(departments.slice(i*departmentsPerColumn, (i+1)*departmentsPerColumn))
		}

		return(
			tmpList.map((col) => {
				return(
					<div className="col-md-4">
						{col}
					</div>
				)
			})	
		)


	}

	mappedDepartments(){

		return this.state.jobs.map((data) => {
			let positions = this.mappedPositions(data.positions)
			return(
				<div key={data.department}>
					<p className="mt-4 h5">{data.department}</p>
					{positions}
				</div>
				)
		})
	}

	mappedPositions(positions){
		return positions.map((position) => {

			return(
					<div key={position.id}>
						<a href={position.absolute_url}>{position.title}</a>
						<p>{ position.location.name}</p>
					</div>
				)
		})

	}

	render(){

		const { text } = this.props.pageContext
		const t = useTranslations(text)
		let openPositions = this.mappedColumns()

		const topSectionContainer = {
			backgroundImage:`url(${heroBg})` ,
			backgroundSize:`cover`
		}


		return (
		<>
			<div className="top-section-container" style={topSectionContainer}>
				<div className="text-center py-5">
				<div className="container py-5" >
					<p className="h1 m-0 pt-5 pb-4 text-white"><span class='bt-font'>BitTorrent</span> <span className="font-weight-normal">{t(`Jobs`)}</span></p>
					<Link to={`${this.props.location.pathname}#openPositions`} id='company-jobs-viewopen' className="btn jobs-btn" >{t(`View Open Positions`)}</Link>
				</div>
				</div>
			</div>
      		<div className="container my-5 pb-3" >
				<h1 className="h3">{t("Our Mission")}</h1>
				<p>
					{t("BitTorrent is the company behind the largest decentralized P2P protocol for distributing files over the Internet. Our team doesn’t just create products. We create technologies that contribute to a more decentralized Internet, powered by the people.")}
				</p>
				<p>
					{t("Our products are available across two brands, BitTorrent (<a id='company-jobs-bt-link' href='https://www.bittorrent.com'>https://www.bittorrent.com</a>) and µTorrent (<a id='company-jobs-ut-link' href='https://www.utorrent.com'>https://www.utorrent.com</a>). With over 100 million active users, BitTorrent offers both desktop-based and browser-based torrent programs for Windows and Mac, as well as mobile torrent downloaders for Android available on Google Play.")}
				</p>

				<h2 className="h3 mt-5 mb-3">{t("Our Perks")}</h2>
				<div className="row">
					<div className="col-12 col-md-4">
						<p className="font-weight-bold mb-1">{t("Salaries & Benefits")}</p>
						<p>{t("Competitive salary, 401K, and 100% paid health coverage for you and your family that includes dental and vision plans.")}</p>
					</div>
					<div className="col-12 col-md-4">
						<p className="font-weight-bold mb-1">{t("Work & Life")}</p>
						<p>{t("Flexible working hours, including the ability to work from home (on occasion or as needed).")}</p>
					</div>
					<div className="col-12 col-md-4">
						<p className="font-weight-bold mb-1">{t("Food & Drink")}</p>
						<p>{t("Plenty of free food at the office, 4 rotating kegs on tap, and yummy catered lunches.")}</p>
					</div>
					<div className="col-12 col-md-4">
						<p className="font-weight-bold mb-1">{t("Activities & Health")}</p>
						<p>{t("Regular team-wide outdoor events and parties and local gym membership (or $50/month toward a gym of your choice).")}</p>
					</div>
					<div className="col-12 col-md-4">
						<p className="font-weight-bold mb-1">{t("Tools & Gear")}</p>
						<p>{t("Your choice of the latest, top-of-the-line equipment and tools to work with.")}</p>
					</div>
					<div className="col-12 col-md-4">
						<p className="font-weight-bold mb-1">{t("Fun & Smart People")}</p>
						<p>{t("Work with smart, awesome people. Can you beat them at pool, pinball or foosball?")}</p>
					</div>
				</div>

					<h2 className="h3 mt-5 mb-3">{t("Our People")}</h2>
					<img className="col-12 col-md-3 mt-1 p-1" src={img1} alt="BitTorrent team building on the bay"></img>
					<img className="col-12 col-md-3 mt-1 p-1" src={img2} alt="BitTorrent VP of Product, Justin Knoll"></img>
					<img className="col-12 col-md-3 mt-1 p-1" src={img3} alt="BitTorrent tech talk"></img>
					<img className="col-12 col-md-3 mt-1 p-1" src={img4} alt="BitTorrent data and product team meeting"></img>
					<img className="col-12 col-md-3 mt-1 p-1" src={img5} alt="Recognition for hard work done"></img>
					<img className="col-12 col-md-3 mt-1 p-1" src={img6} alt="BitTorrent CEO, Justin Sun"></img>
					<img className="col-12 col-md-3 mt-1 p-1" src={img7} alt="BitTorrent Friday all hands group photo"></img>
					<img className="col-12 col-md-3 mt-1 p-1" src={img8} alt="BitTorrent team building, food and wine"></img>

					<p className="h3 mt-5" id="openPositions">{t("Our Open Positions")}</p>
					<CSSTransition in={this.state.show} timeout={500} classNames="fade-animation">
						<div>
							<div className="row">
							  { openPositions }
							</div>
						</div>
					</CSSTransition>
				</div>

		</>
		)
	}
}

export default CompanyJobs